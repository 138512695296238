import { ContainerConfig } from 'BaxterScript/types/Config';
import * as Objects from 'BaxterScript/helper/object/Object';
import * as State from 'BaxterScript/version/web/core/State';
import * as Cookie from 'BaxterScript/helper/browser/Cookie';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';

enum TrackedTargetingParams {
  user_id = 'user_id',
  cat_l0_id = 'cat_l0_id',
  cat_l1_id = 'cat_l1_id',
  cat_l2_id = 'cat_l2_id',
}

export const createTargetingParams = (pageId: string, containerConfig: ContainerConfig) =>
  Objects.clone({
    ...State.getPageParams(),
    page: pageId,
    container: containerConfig.id,
    ...(State.getSessionLong() ? { sessionLong: State.getSessionLong() } : null),
    featureFlags: Cookie.get('laquesisff')?.split('#') || [],
    abTest: Cookie.get('laquesis')?.split('#') || [],
  });

export const getTrackedParams = (targeting: TargetingParams) =>
  Object.entries(targeting)
    .filter(([key]) => TrackedTargetingParams[key])
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    );

import * as CmdQueue from 'BaxterScript/helper/array/CmdQueue';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { BaxterContext } from 'BaxterScript/version/web/context/BaxterContext';
import { handleSlotRenderedEvent, SLOT_RENDERED_EVENT } from 'BaxterScript/version/web/core/SlotRenderedEventListener';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';
import ConsentV2 from 'BaxterScript/version/web/feature/ConsentV2';
import StyleV2 from 'BaxterScript/version/web/feature/StyleV2';
import TimerRefreshV2 from 'BaxterScript/version/web/feature/TimerRefreshV2';
import * as Container from 'BaxterScript/version/web/core/Container';
import * as State from './core/State';
import * as Event from './core/Event';
import * as Provider from './core/Provider';
import * as ProviderV2 from './core/ProviderV2';
import * as LifeCycle from './core/Lifecycle';
import * as LifeCycleV2 from './core/LifecycleV2';
import * as Polyfill from './core/Polyfill';
import * as Preview from './core/Preview';
import * as Interstitial from './provider/googleads/Interstitial';
import Consent from './feature/Consent';
import Style from './feature/Style';
import AdBlock from './feature/AdBlock';
import Cxense from './feature/Cxense';
import Scripts from './feature/Scripts';
import Gemius from './feature/Gemius';
import Sati from './feature/Sati';

globalThis.Baxter = globalThis.Baxter || {};
globalThis.Baxter.queue = globalThis.Baxter.queue || [];

const initialize = async () => {
  Polyfill.bootstrap();
  if (Style) Style.bootstrap();
  if (AdBlock) AdBlock.bootstrap();
  if (Cxense) Cxense.bootstrap();
  if (Sati) Sati.bootstrap();
  if (Gemius) Gemius.bootstrap();
  if (Scripts) Scripts.bootstrap();
  if (Consent) Consent.bootstrap();
  State.bootstrap();
  Provider.bootstrap();
  Event.bootstrap();
  try {
    if (Interstitial && typeof Interstitial?.bootstrap === 'function') {
      Interstitial?.bootstrap();
    } else {
      console.debug('[SLOTS][INITJS] Interstitial not available');
    }
  } catch (e) {
    console.error('[SLOTS][INITJS] Interstitial init error', e);
    newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_INIT_ERROR, { message: (e as Error).message });
  }
  await CmdQueue.init(globalThis.Baxter.queue);
};

const initializeV2 = async () => {
  Polyfill.bootstrap();
  if (StyleV2) StyleV2.bootstrap();
  if (AdBlock) AdBlock.bootstrap();
  if (Cxense) Cxense.bootstrap();
  if (Sati) Sati.bootstrap();
  if (Gemius) Gemius.bootstrap();
  if (Scripts) Scripts.bootstrap();
  if (ConsentV2) ConsentV2.bootstrap();
  if (TimerRefreshV2) TimerRefreshV2.bootstrap(Container.setSlot);
  State.bootstrap();
  ProviderV2.bootstrap();
  await CmdQueue.init(globalThis.Baxter.queue);
};

if (!globalThis.Baxter.initialized) {
  globalThis.Baxter.initialized = true;
  /* eslint-disable no-undef */
  globalThis.Baxter.config = __CONFIG__;
  globalThis.Baxter.context = new BaxterContext(globalThis.Baxter.config);
  // todo: merge into config before runtime
  globalThis.Baxter.config.accountId = __ACCOUNT_ID__;
  globalThis.Baxter.config.appId = __APP_ID__;
  globalThis.Baxter.config.versionId = __VERSION_ID__;
  globalThis.Baxter.config.commitHash = __COMMIT_HASH__;
  globalThis.Baxter.config.environment = __ENVIRONMENT__;
  globalThis.Baxter.config.cdnDomain = __CDN_DOMAIN__;
  globalThis.Baxter.config.apiDomain = __API_DOMAIN__;
  // todo: set in 'baxter-native' provider config (not env var)??
  globalThis.Baxter.config.adsDomain = __ADS_DOMAIN__;
  /* eslint-disable no-undef */
  const v2Enabled = baxterV2Enabled(globalThis.Baxter.config);

  globalThis.Baxter.state = {
    providers: [],
    page: {
      id: null,
      params: {},
    },
    placeholders: {},
    containers: {},
    slots: {},
    user: {
      observers: {},
      timers: {
        generals: [],
        containers: {},
      },
      cxense: {},
      sati: {
        init: false,
      },
      active: true,
      consentResolved: false,
    },
    app: {
      breakpoints: [],
      set: {},
    },
    trackers: [],
  };

  newRelicMetrics.reportMetric(NewRelicMetric.SCRIPT_INITIALIZED);

  if (v2Enabled) {
    console.debug('[SLOTS][INITJS][V2ENABLED]');
    globalThis.Baxter.consent = ConsentV2
      ? ConsentV2.setUserConsent
      : async () => {
          console.debug('[SLOTS][CONSENT][SETUSERCONSENT] disabled in slots so do nothing');
        };
    globalThis.Baxter.page = LifeCycleV2.onPageChanged;
    globalThis.Baxter.set = LifeCycleV2.onSet;
    globalThis.Baxter.clear = LifeCycleV2.onClear;
    globalThis.Baxter.track = LifeCycleV2.onPageTrack;
    globalThis.Baxter.setPageParams = LifeCycleV2.onSetPageParams;
  } else {
    globalThis.Baxter.consent = Consent
      ? Consent.setUserConsent
      : async () => {
          console.debug('[SLOTS][CONSENT][SETUSERCONSENT] disabled in slots so do nothing');
        };
    globalThis.Baxter.page = LifeCycle.onPageChanged;
    globalThis.Baxter.set = LifeCycle.onSet;
    globalThis.Baxter.setAfterLoaded = LifeCycle.onSetAfterLoaded;
    globalThis.Baxter.clear = LifeCycle.onClear;
    globalThis.Baxter.track = LifeCycle.onPageTrack;
    globalThis.Baxter.setPageParams = LifeCycle.onSetPageParams;
  }

  (async () => {
    try {
      if (v2Enabled) {
        console.info('[SLOTS][INITJS] INIT SCRIPT LOADED');
        if (await Preview.init()) {
          console.info('[SLOTS][INITJS] PREVIEW SCRIPT LOADED');
        } else if (globalThis.Baxter.config.app.enabled) {
          console.info('[SLOTS][INITJS] INITIALIZING', globalThis.Baxter.config);
          await initializeV2();
        }
      } else {
        console.info('[SLOTS][INITJS] INIT SCRIPT LOADED');
        window.addEventListener(SLOT_RENDERED_EVENT, handleSlotRenderedEvent);
        if (await Preview.init()) {
          console.info('[SLOTS][INITJS] PREVIEW SCRIPT LOADED');
        } else if (globalThis.Baxter.config.app.enabled) {
          console.info('[SLOTS][INITJS] INITIALIZING', globalThis.Baxter.config);
          await initialize();
        }
      }
    } catch (e) {
      console.error('[SLOTS][INITJS]', e);
      newRelicMetrics.reportError(NewRelicError.SCRIPT_ERROR, { message: (e as Error).message });
    }
  })();
}

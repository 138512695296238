export enum NewRelicError {
  SCRIPT_ERROR = 'ScriptError',
  SCRIPT_QUEUE_ERROR = 'ScriptQueueError',
  LAZY_LOAD_EVENT_LISTENER_ERROR = 'LazyLoadEventListenerError',
  LAZY_LOAD_INTERSECTION_OBSERVER_ERROR = 'LazyLoadIntersectionObserverError',
  TIMER_REFRESH_TIMEOUT_ERROR = 'TimerRefreshTimerError',
  TIMER_REFRESH_INVALID_TIMEOUT = 'TimerRefreshInvalidTimeout',
  TIMER_REFRESH_INTERSECTION_OBSERVER_ERROR = 'TimerRefreshIntersectionObserverError',
  TIMER_REFRESH_VISIBLE_BEFORE_TIMEOUT_OBSERVER_ERROR = 'TimerRefreshVisibleBeforeTimeoutObserverError',
  TIMER_REFRESH_VISIBLE_AFTER_TIMEOUT_INTERSECTION_OBSERVER_ERROR = 'TimerRefreshVisibleAfterTimeoutObserverError',
  TIMER_REFRESH_INTERVAL_ERROR = 'TimerRefreshIntervalError',
  TIMER_REFRESH_MOUSE_MOVE_AND_SCROLL_ERROR = 'TimerRefreshMouseMoveAndScrollError',
  TIMER_REFRESH_VISIBILITY_CHANGE_ERROR = 'TimerRefreshVisibilityChangeError',
  FALLBACK_ERROR = 'FallbackError',
  AUTOPLAY_INTERSECTION_OBSERVER_ERROR = 'AutoPlayIntersectionObserverError',
  AUTOPLAY_RESIZE_OBSERVER_ERROR = 'AutoPlayResizeObserverError',
  AUTOPLAY_VISIBILITY_CHANGE_ERROR = 'AutoPlayVisibilityChangeError',
  PLACEHOLDER_SET_CONTAINER_REDIRECT_URL_ERROR = 'PlaceholderSetContainerRedirectUrlError',
  INTERSTITIAL_INIT_ERROR = 'InterstitialInitError',
  INTERSTITIAL_LOAD_ERROR = 'InterstitialLoadError',
  INTERSTITIAL_CLOSE_MODAL_TIMEOUT_ERROR = 'InterstitialCloseModalTimeoutError',
  INTERSTITIAL_SET_AUTO_CLOSE_TIMEOUT_ERROR = 'InterstitialSetAutoCloseTimeoutError',
  INTERSTITIAL_SHOW_CLOSE_TIMER_ERROR = 'InterstitialShowCloseTimerError',
  INTERSTITIAL_CLOSE_CLICK_HANDLER_ERROR = 'InterstitialCloseClickHandlerError',
  INTERSTITIAL_BOOTSTRAP_LISTENER_ERROR = 'InterstitialBootstrapListenerError',
  CONTAINER_SET_SLOT_ERROR = 'ContainerSetSlotError',
  SLOT_NO_CONTAINER_ID_OR_PAGE_ID = 'SlotNoContainerIdOrPageId',
  SLOT_CONTAINER_SET_TOO_RECENTLY = 'SlotContainerSetTooRecently',
  SLOT_PROVIDER_MODULE_FOR_TRANSFORM_NOT_FOUND = 'SlotProviderModuleForTransformNotFound',
  CONTAINER_DIV_NOT_FOUND = 'ContainerDivNotFound',
  CONTAINER_INNER_DIV_NOT_FOUND = 'ContainerInnerDivNotFound',
  SLOT_NO_PROVIDER = 'SlotNoProvider',
  SLOT_PROVIDER_MODULE_FOR_CREATE_NOT_FOUND = 'SlotProviderModuleForCreateNotFound',
  SLOT_PROVIDER_MODULE_NOT_FOUND = 'SlotProviderModuleNotFound',
  SLOT_PROVIDER_REMOVE_THROWN_ERROR = 'SlotProviderRemoveThrownError',
  SLOT_PROVIDER_REMOVE_RETURNED_ERROR = 'SlotProviderRemoveReturnedError',
  SLOT_PROVIDER_REFRESH_THROWN_ERROR = 'SlotProviderRefreshThrownError',
  SLOT_PROVIDER_REFRESH_RETURNED_ERROR = 'SlotProviderRefreshReturnedError',
  CONDITION_CHECK_ERROR = 'ConditionCheckError',
  PROVIDER_BOOTSTRAP_ERROR = 'ProviderBootstrapError',
  PROVIDER_CONSENT_ERROR = 'ProviderConsentError',
  PROVIDER_TRANSFORM_ERROR = 'ProviderTransformError',
  PROVIDER_CREATE_ERROR = 'ProviderCreateError',
  PROVIDER_LOAD_ERROR = 'ProviderLoadError',
  PROVIDER_AUTOPLAY_ERROR = 'ProviderAutoplayError',
  PROVIDER_REMOVE_ERROR = 'ProviderRemoveError',
  PROVIDER_SET_PAGE_TARGETING_ERROR = 'ProviderSetPageTargetingError',
  GOOGLEADS_COMMAND_ERROR = 'GoogleAdsCmdFailed',
  GOOGLEADS_NO_EXTERNAL = 'GoogleAdsNoExternal',
  GOOGLEADS_NO_GOOGLE_TAG = 'GoogleAdsNoGoogleTag',
  GOOGLEADS_IMPRESSION_CALLBACK_ERROR = 'GoogleAdsImpressionCallbackError',
  GOOGLEADS_RENDERED_CALLBACK_ERROR = 'GoogleAdsRenderedCallbackError',
  GOOGLEADS_REQUESTED_CALLBACK_ERROR = 'GoogleAdsRequestedCallbackError',
  GOOGLEADS_RESPONSE_RECEIVED_CALLBACK_ERROR = 'GoogleAdsResponseReceivedCallbackError',
  BAXTER_FETCH_AD_ERROR = 'BaxterFetchAdError',
  BAXTER_RENDERING_ERROR = 'BaxterRenderingError',
  BAXTER_CONTAINER_NOT_VISIBLE = 'BaxterContainerNotVisible',
  BAXTER_CONTAINER_DIV_NOT_FOUND = 'BaxterContainerDivNotFound',
  ADMANAGER_STATIC_FETCH_AD_ERROR = 'AdManagerStaticFetchAdError',
  ADMANAGER_STATIC_LOAD_ERROR = 'AdManagerStaticLoadError',
  ADMANAGER_STATIC_RENDERING_ERROR = 'AdManagerStaticRenderingError',
  ADMANAGER_STATIC_CONTAINER_NOT_VISIBLE = 'AdManagerStaticContainerNotVisible',
  ADMANAGER_STATIC_CONTAINER_DIV_NOT_FOUND = 'AdManagerStaticContainerDivNotFound',
  ADMANAGER_STATIC_DSA_MODAL_OPEN_ERROR = 'AdManagerStaticDsaNodalOpenError',
  ADMANAGER_STATIC_DSA_MODAL_CLOSE_ERROR = 'AdManagerStaticDsaNodalCloseError',
  ADMANAGER_CLICK_HANDLER_ERROR = 'AdmanagerClickHandlerError',
  ADMANAGER_INVALID_CREATIVE_TYPE = 'AdmanagerInvalidCreativeType',
  ADMANAGER_LOAD_ERROR = 'AdmanagerLoadError',
  ADSENSE_CREATE_AD_LOADED_CALLBACK_ERROR = 'AdsenseCreateAdLoadedCallbackError',
  ADSENSE_NO_GOOG_CSA = 'AdSenseNoGoogCsa',
  ADSENSE_LOAD_ERROR = 'AdSenseLoadError',
  FACEBOOK_LOAD_ERROR = 'FacebookLoadError',
  CXENSE_CREATE_AD_BLOCK_PIXEL_ERROR = 'CxenseCreateAdBlockPixelError',
  CXENSE_SET_SEGMENTS_ERROR = 'CxenseSetSegmentsError',
  CXENSE_SEND_PAGE_VIEW_EVENT_QUEUE_ERROR = 'CxenseSendPageViewEventQueueError',
  CXENSE_SEND_PAGE_VIEW_EVENT_ERROR = 'CxenseSendPageViewEventError',
  SATI_ADD_PIXEL_ERROR = 'SatiAddPixelError',
  SATI_INIT_ERROR = 'SatiInitError',
  CONSENT_SET_USER_CONSENT = 'ConsentSetUserConsent',
  LIFECYCLE_ON_CLEAR_ERROR = 'LifecycleOnClearError',
  LIFECYCLE_ON_CLEAR_PROVIDER_ERROR = 'LifecycleOnClearProviderError',
  LIFECYCLE_NO_DEFFERED_CONTAINERS_FOUND = 'LifecycleNoDefferedContainersFound',
  LIFECYCLE_ON_SET_ERROR = 'LifecycleOnSetError',
  LIFECYCLE_ON_SET_AFTER_LOADED_ERROR = 'LifecycleOnSetAfterLoadedError',
  LIFECYCLE_NO_CONTAINERS_FOUND_FOR_PAGE = 'LifecycleNoContainersFoundForPage',
  LIFECYCLE_ON_LOAD_ERROR = 'LifecycleOnLoadError',
  LIFECYCLE_ON_CONTAINER_RESIZE_ERROR = 'LifecycleOnContainerResizeError',
  LIFECYCLE_ON_CONTAINER_INTERSECTION_ERROR = 'LifecycleOnContainerIntersectionError',
  LIFECYCLE_SLOTS_NOT_LOADED = 'LifecycleSlotsNotLoaded',
  LIFECYCLE_SLOT_DISAPPEARED = 'LifecycleSlotDisappeared',
  LIFECYCLE_ON_PAGE_PARAMS_ERROR = 'LifecycleOnPageParamsError',
  LIFECYCLE_ON_PAGE_CHANGED_ERROR = 'LifecycleOnPageChangedError',
  LIFECYCLE_ON_PAGE_TRACK_ERROR = 'LifecycleOnPageTrackError',
  GOOGLEIMA_DEPENDENCIES_NOT_RESOLVED = 'GoogleImaDependenciesNotResolved',
  GOOGLEIMA_INIT_AD_DISPLAY_CONTAINER = 'GoogleImaInitAdDisplayContainer',
  GOOGLEIMA_ERROR = 'GoogleImaError',
  GOOGLEIMA_LOAD_ERROR = 'GoogleImaLoadError',
  NINJA_REPORTING_ERROR = 'NinjaReportingError',
  PREBID_QUE_ERROR = 'PrebidQueFailed',
  PREBID_NO_PBJS = 'PrebidNoPbjs',
  APS_NO_APS = 'ApsNoAps',
  BIDDERS_TIMEOUT_ERROR = 'BiddersTimeoutError',
  STICKY_ADD_CLOSE_BUTTON_TIMEOUT_ERROR = 'StickyAddCloseButtonTimeoutError',
  STICKY_REMOVE_CLOSE_BUTTON_TIMEOUT_ERROR = 'StickyRemoveCloseButtonTimeoutError',
  STICKY_INTERSECTION_OBSERVER_ERROR = 'StickyIntersectionObserverError',
  STICKY_RESIZE_OBSERVER_ERROR = 'StickyResizeObserverError',
  STICKY_CLOSE_BUTTON_EVENT_LISTINER_ERROR = 'StickyCloseButtonEventListinerError',
  EVENT_HANDLE_RESIZE_ERROR = 'EventHandleResizeResizeError',
  EVENT_HANDLE_SLOT_RENDERED_ERROR = 'EventHandleSlotRenderedError',
  EVENT_HANDLE_URL_CHANGE_ERROR = 'EventHandleUrlChangeError',
  EVENT_HANDLE_USER_ACTIVE_ERROR = 'EventHandleUserActiveError',
  EVENT_HANDLE_ADBLOCK_DETECT_ERROR = 'EventHandleAdblockDetectError',
}

import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import {
  GoogleAdsSlot,
  ImpressionViewableCallback,
  SlotRenderEndedCallback,
  GoogleSlotExternal,
  SlotRequestedCallback,
  SlotResponseReceivedCallback,
  Slot,
} from 'BaxterScript/types/Slot';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';

export const googleSlotRequestedCallback =
  (
    slot: Slot,
    external: GoogleSlotExternal,
    parameters: Record<string, unknown>,
    slotRequestedCallback: SlotRequestedCallback
  ) =>
  (event) => {
    try {
      if (external === event.slot) {
        console.info('[SLOTS][GOOGLEADS][REQUESTEDCALLBACK]', event);
        slotRequestedCallback(slot, parameters);
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][REQUESTEDCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_REQUESTED_CALLBACK_ERROR, { message: (e as Error).message });
    }
  };

export const googleSlotResponseReceivedCallback =
  (
    slot: Slot,
    external: GoogleSlotExternal,
    parameters: Record<string, unknown>,
    slotResponseReceivedCallback: SlotResponseReceivedCallback
  ) =>
  (event) => {
    try {
      if (external === event.slot) {
        console.info('[SLOTS][GOOGLEADS][RESPONSERECEIVEDCALLBACk]', event);
        slotResponseReceivedCallback(slot, parameters);
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][RESPONSERECEIVEDCALLBACk]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_RESPONSE_RECEIVED_CALLBACK_ERROR, {
        message: (e as Error).message,
      });
    }
  };

export const googleImpressionViewableCallback =
  (
    slot: GoogleAdsSlot,
    external: GoogleSlotExternal,
    parameters: Record<string, unknown>,
    impressionViewableCallback: ImpressionViewableCallback
  ) =>
  (event) => {
    try {
      if (event.slot === external) {
        console.info('[SLOTS][GOOGLEADS][IMPRESSIONCALLBACK]', event.slot.getSlotElementId());
        impressionViewableCallback(slot, slot[Providers.GOOGLE_ADS].state.loadSource as string, parameters);
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][IMPRESSIONCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_IMPRESSION_CALLBACK_ERROR, { message: (e as Error).message });
    }
  };

export const googleSlotRenderEndedCallback =
  (
    slot: GoogleAdsSlot,
    external: GoogleSlotExternal,
    parameters: Record<string, unknown>,
    slotRenderEndedCallback: SlotRenderEndedCallback
  ) =>
  (event) => {
    try {
      if (event.slot === external) {
        console.info('[SLOTS][GOOGLEADS][RENDEREDCALLBACK]', slot.containerId, slot.id, event.slot.getSlotElementId());
        if (slot[Providers.GOOGLE_ADS].state.alreadyRemoved) {
          console.debug('[SLOTS][GOOGLEADS][RENDEREDCALLBACK] slot already removed', slot);
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_SLOT_ALREADY_REMOVED, {
            place: 'slotRenderEndedCallback',
          });
          return;
        }
        // eslint-disable-next-line no-param-reassign
        slot.params.campaignId = external.getResponseInformation?.()?.campaignId || 0;
        slotRenderEndedCallback(
          slot[Providers.GOOGLE_ADS].state.loadSource as string,
          slot,
          event.isEmpty,
          false,
          parameters
        );
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][RENDEREDCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_RENDERED_CALLBACK_ERROR, { message: (e as Error).message });
    }
  };

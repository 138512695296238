export enum NewRelicError {
  SCRIPT_ERROR = 'ScriptError',
  SCRIPT_QUEUE_ERROR = 'ScriptQueueError',
  LAZY_LOAD_EVENT_LISTENER_ERROR = 'LazyLoadEventListenerError',
  LAZY_LOAD_INTERSECTION_OBSERVER_ERROR = 'LazyLoadIntersectionObserverError',
  TIMER_REFRESH_TIMEOUT_ERROR = 'TimerRefreshTimerError',
  TIMER_REFRESH_INVALID_TIMEOUT = 'TimerRefreshInvalidTimeout',
  TIMER_REFRESH_INTERSECTION_OBSERVER_ERROR = 'TimerRefreshIntersectionObserverError',
  TIMER_REFRESH_VISIBLE_BEFORE_TIMEOUT_OBSERVER_ERROR = 'TimerRefreshVisibleBeforeTimeoutObserverError',
  TIMER_REFRESH_INTERVAL_ERROR = 'TimerRefreshIntervalError',
  TIMER_REFRESH_MOUSE_MOVE_AND_SCROLL_ERROR = 'TimerRefreshMouseMoveAndScrollError',
  TIMER_REFRESH_VISIBILITY_CHANGE_ERROR = 'TimerRefreshVisibilityChangeError',
  FALLBACK_INTERSECTION_OBSERVER_ERROR = 'FallbackIntersectionObserverError',
  AUTOPLAY_INTERSECTION_OBSERVER_ERROR = 'AutoPlayIntersectionObserverError',
  AUTOPLAY_RESIZE_OBSERVER_ERROR = 'AutoPlayResizeObserverError',
  AUTOPLAY_VISIBILITY_CHANGE_ERROR = 'AutoPlayVisibilityChangeError',
  PLACEHOLDER_SET_CONTAINER_REDIRECT_URL_ERROR = 'PlaceholderSetContainerRedirectUrlError',
  INTERSTITIAL_MISSING_TOP_LEVEL_DIV = 'InterstitialMissingTopLevelDiv',
  INTERSTITIAL_SHOW_MODAL_ERROR = 'InterstitialShowModalError',
  INTERSTITIAL_CLOSE_MODAL_TIMEOUT_ERROR = 'InterstitialCloseModalTimeoutError',
  INTERSTITIAL_SET_AUTO_CLOSE_TIMEOUT_ERROR = 'InterstitialSetAutoCloseTimeoutError',
  INTERSTITIAL_SHOW_CLOSE_TIMER_ERROR = 'InterstitialShowCloseTimerError',
  INTERSTITIAL_CLOSE_CLICK_HANDLER_ERROR = 'InterstitialCloseClickHandlerError',
  INTERSTITIAL_BOOTSTRAP_LISTENER_ERROR = 'InterstitialBootstrapListenerError',
  INTERSTITIAL_RESIZE_LISTENER_ERROR = 'InterstitialResizeListenerError',
  INTERSTITIAL_POPSTATE_LISTENER_ERROR = 'InterstitialPopstateListenerError',
  CONTAINER_SET_SLOT_ERROR = 'ContainerSetSlotError',
  CONTAINER_SET_TOO_RECENTLY = 'ContainerSetTooRecently',
  CONTAINER_DIV_NOT_FOUND = 'ContainerDivNotFound',
  CONTAINER_INNER_DIV_NOT_FOUND = 'ContainerInnerDivNotFound',
  CONDITION_CHECK_ERROR = 'ConditionCheckError',
  PROVIDER_BOOTSTRAP_ERROR = 'ProviderBootstrapError',
  PROVIDER_INITIALIZE_ERROR = 'ProviderInitializeError',
  PROVIDER_CREATE_ERROR = 'ProviderCreateError',
  PROVIDER_LOAD_ERROR = 'ProviderLoadError',
  PROVIDER_AUTOPLAY_ERROR = 'ProviderAutoplayError',
  PROVIDER_REMOVE_ERROR = 'ProviderRemoveError',
  PROVIDER_SET_PAGE_TARGETING_ERROR = 'ProviderSetPageTargetingError',
  PROVIDER_SET_PREVIEW_ERROR = 'ProviderSetPreviewError',
  GOOGLEADS_COMMAND_ERROR = 'GoogleAdsCmdFailed',
  GOOGLEADS_NO_EXTERNAL = 'GoogleAdsNoExternal',
  GOOGLEADS_IMPRESSION_CALLBACK_ERROR = 'GoogleAdsImpressionCallbackError',
  GOOGLEADS_RENDERED_CALLBACK_ERROR = 'GoogleAdsRenderedCallbackError',
  GOOGLEADS_REQUESTED_CALLBACK_ERROR = 'GoogleAdsRequestedCallbackError',
  GOOGLEADS_RESPONSE_RECEIVED_CALLBACK_ERROR = 'GoogleAdsResponseReceivedCallbackError',
  ADMANAGER_STATIC_FETCH_AD_ERROR = 'AdManagerStaticFetchAdError',
  ADMANAGER_STATIC_LOAD_ERROR = 'AdManagerStaticLoadError',
  ADMANAGER_STATIC_RENDERING_ERROR = 'AdManagerStaticRenderingError',
  ADMANAGER_STATIC_DSA_MODAL_OPEN_ERROR = 'AdManagerStaticDsaNodalOpenError',
  ADMANAGER_STATIC_DSA_MODAL_CLOSE_ERROR = 'AdManagerStaticDsaNodalCloseError',
  ADMANAGER_CLICK_HANDLER_ERROR = 'AdmanagerClickHandlerError',
  ADMANAGER_INVALID_CREATIVE_TYPE = 'AdmanagerInvalidCreativeType',
  ADMANAGER_LOAD_ERROR = 'AdmanagerLoadError',
  ADSENSE_CREATE_AD_LOADED_CALLBACK_ERROR = 'AdsenseCreateAdLoadedCallbackError',
  ADSENSE_NO_GOOG_CSA = 'AdSenseNoGoogCsa',
  ADSENSE_LOAD_ERROR = 'AdSenseLoadError',
  FACEBOOK_LOAD_ERROR = 'FacebookLoadError',
  CXENSE_CREATE_AD_BLOCK_PIXEL_ERROR = 'CxenseCreateAdBlockPixelError',
  CXENSE_SET_SEGMENTS_ERROR = 'CxenseSetSegmentsError',
  CXENSE_SEND_PAGE_VIEW_EVENT_QUEUE_ERROR = 'CxenseSendPageViewEventQueueError',
  CXENSE_SEND_PAGE_VIEW_EVENT_ERROR = 'CxenseSendPageViewEventError',
  SATI_ADD_PIXEL_ERROR = 'SatiAddPixelError',
  SATI_INIT_ERROR = 'SatiInitError',
  CONSENT_SET_USER_CONSENT = 'ConsentSetUserConsent',
  LIFECYCLE_ON_CLEAR_ERROR = 'LifecycleOnClearError',
  LIFECYCLE_ON_SET_ERROR = 'LifecycleOnSetError',
  LIFECYCLE_ON_SET_DEBOUNCED = 'LifecycleOnSetDebounced',
  LIFECYCLE_NO_CONTAINERS_FOUND_FOR_PAGE = 'LifecycleNoContainersFoundForPage',
  LIFECYCLE_SLOT_DISAPPEARED = 'LifecycleSlotDisappeared',
  LIFECYCLE_ON_PAGE_PARAMS_ERROR = 'LifecycleOnPageParamsError',
  LIFECYCLE_ON_PAGE_CHANGED_ERROR = 'LifecycleOnPageChangedError',
  LIFECYCLE_ON_PAGE_SAME_PARAMS = 'LifecycleOnPageSameParams',
  LIFECYCLE_SET_CONTAINER_SLOT_ERROR = 'LifecycleSetContainerSlotError',
  GOOGLEIMA_DEPENDENCIES_NOT_RESOLVED = 'GoogleImaDependenciesNotResolved',
  GOOGLEIMA_INIT_AD_DISPLAY_CONTAINER = 'GoogleImaInitAdDisplayContainer',
  GOOGLEIMA_ERROR = 'GoogleImaError',
  GOOGLEIMA_LOAD_ERROR = 'GoogleImaLoadError',
  NINJA_REPORTING_ERROR = 'NinjaReportingError',
  PREBID_QUE_ERROR = 'PrebidQueFailed',
  PREBID_NO_PBJS = 'PrebidNoPbjs',
  APS_NO_APS = 'ApsNoAps',
  BIDDERS_TIMEOUT_ERROR = 'BiddersTimeoutError',
  STICKY_ADD_CLOSE_BUTTON_TIMEOUT_ERROR = 'StickyAddCloseButtonTimeoutError',
  STICKY_REMOVE_CLOSE_BUTTON_TIMEOUT_ERROR = 'StickyRemoveCloseButtonTimeoutError',
  STICKY_INTERSECTION_OBSERVER_ERROR = 'StickyIntersectionObserverError',
  STICKY_RESIZE_OBSERVER_ERROR = 'StickyResizeObserverError',
  STICKY_CLOSE_BUTTON_EVENT_LISTINER_ERROR = 'StickyCloseButtonEventListinerError',
  BREAKPOINT_REFRESH_HANDLE_RESIZE_ERROR = 'BreakpointRefreshHandleResizeResizeError',
  ADBLOCK_DETECT_ERROR = 'AdblockDetectError',
  COOKIE_DECODE_ERROR = 'CookieDecodeError',
  NEW_RELIC_TEST_ERROR = 'NewRelicTestError',
}

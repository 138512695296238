import * as Html from 'BaxterScript/helper/browser/Html';
import * as Slot from 'BaxterScript/version/web/core/Slot';
import * as State from 'BaxterScript/version/web/core/State';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Config } from 'BaxterScript/types/Config';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';
import { Features } from 'BaxterScript/version/web/config/Features';

export const webpackExclude = (config: Config): boolean => {
  const settings = config.slots?.settings?.timerRefresh;
  return (
    !(
      (settings?._ && Object.values(settings._).some((item) => !!item?.enabled)) ||
      (settings && Object.values(settings).some((item) => !!item?.enabled))
    ) || baxterV2Enabled(config)
  );
};

const TIMER_KEY = 'timer-refresh';

const getSettings = (pageId, containerId, slotId) =>
  globalThis.Baxter.context.configurationService.getTimeRefresh(pageId, containerId, slotId);

const isEnabledForSlot = (pageId, containerId, slotId) => {
  const settings = getSettings(pageId, containerId, slotId);
  return settings && settings.enabled;
};

const startCountdown = (pageId, containerId, slotId) => {
  const slot = State.getSlot(containerId);
  if (slot) {
    slot.status.refreshPending = false;
  }
  State.removeContainerTimer(containerId, TIMER_KEY);

  const settings = getSettings(pageId, containerId, slotId) || {};
  // eslint-disable-next-line no-restricted-globals
  if (settings.enabled && settings.timeout && !isNaN(settings.timeout)) {
    const timer = setTimeout(
      async () => {
        try {
          console.info('[SLOTS][TIMERREFRESH][STARTCOUNTDOWN] refresh attempt', containerId, settings);
          State.removeContainerTimer(containerId, TIMER_KEY);
          await refreshSlot(containerId, settings);
        } catch (e) {
          console.error('[SLOTS][TIMERREFRESH][STARTCOUNTDOWN][TIMEOUT]', e);
          newRelicMetrics.reportError(NewRelicError.TIMER_REFRESH_TIMEOUT_ERROR, {
            message: (e as Error).message,
          });
        }
      },
      parseInt(settings.timeout, 10) * 1000
    );
    State.addContainerTimer(containerId, TIMER_KEY, timer);
  } else if (settings.enabled) {
    console.error('[SLOTS][TIMERREFRESH][STARTCOUNTDOWN] invalid timeout', pageId, containerId, slotId);
    newRelicMetrics.reportError(NewRelicError.TIMER_REFRESH_INVALID_TIMEOUT, { containerId, slotId });
  }
};

const refreshSlot = async (containerId, settings) => {
  const slot = State.getSlot(containerId);
  if (slot) {
    if (slot.status.visible && State.getUserActive()) {
      if (settings.containerId && Html.getElementById(settings.containerId)) {
        console.debug('[SLOTS][TIMERREFRESH][REFRESHSLOT] element exists Slot.set', settings.containerId, slot.params);
        slot.status.refreshCount = 0;
        await Slot.set(Features.TIMER_REFRESH, settings.containerId, slot.params, false);
        await Slot.remove(containerId);
      } else {
        console.debug("[SLOTS][TIMERREFRESH][REFRESHSLOT] element doesn't exist Slot.set", containerId, slot.params);
        slot.status.refreshCount++;
        await Slot.set(Features.TIMER_REFRESH, containerId, slot.params, false);
      }
    } else {
      slot.status.refreshPending = true;
    }
  }
};

export default {
  getSettings,
  isEnabledForSlot,
  startCountdown,
  refreshSlot,
};

export enum NewRelicMetric {
  CONSENT_RESOLVED = 'BaxterConsentResolved',
  CONSENT_MISSING_ACTIVE_GROUPS = 'BaxterConsentMissingActiveGroups',
  SCRIPT_INITIALIZED = 'BaxterInitialized',
  AD_RENDERED = 'BaxterSlotRendered',
  AD_IMPRESSION = 'BaxterSlotImpression',
  AD_CLICKED = 'BaxterSlotClicked',
  CONTAINER_SET_SLOT = 'BaxterContainerSetSlot',
  CONTAINER_NO_MATCHING_SLOT = 'BaxterContainerNoMatchingSlot',
  CONTAINER_SLOT_LAZY_LOAD_APPLIED = 'BaxterContainerSlotLazyLoadApplied',
  CONTAINER_LOADABLE = 'ContainerLoadable',
  INTERSTITIAL_INVALID_FREQUENCY_CAP = 'BaxterInterstitialInvalidFrequencyCap',
  INTERSTITIAL_SHOW_MODAL = 'BaxterInterstitialShowModal',
  INTERSTITIAL_INVALID_SIZE = 'BaxterInterstitialInvalidSize',
  INTERSTITIAL_CLOSE_MODAL = 'BaxterInterstitialCloseModal',
  INTERSTITIAL_CLEAR_MODAL = 'BaxterInterstitialClearModal',
  GOOGLEADS_DESTROY_SLOTS_IN_REMOVE_DESTROYED_NOTHING = 'BaxterGoogleAdsDestroySlotsInRemoveDestroyedNothing',
  GOOGLEADS_DESTROY_SLOTS_IN_REMOVE_WITH_CLEAR_DESTROYED_NOTHING = 'BaxterGoogleAdsDestroySlotsInRemoveWithClearDestroyedNothing',
  GOOGLEADS_SLOT_ALREADY_REMOVED = 'BaxterGoogleAdsSlotAlreadyRemoved',
  PREBID_CURRENCY_CONFIG = 'BaxterPrebidCurrencyConfig',
  PREBID_REQUESTED_BIDS = 'BaxterPrebidRequestedBids',
  PREBID_SUCCESSFULLY_RETURNED = 'BaxterPrebidSuccessfullyReturned',
  PREBID_TIMED_OUT = 'BaxterPrebidTimedOut',
  APS_REQUESTED_BIDS = 'BaxterApsRequestedBids',
  APS_SUCCESSFULLY_RETURNED = 'BaxterApsSuccessfullyReturned',
  APS_TIMED_OUT = 'BaxterApsTimedOut',
  BIDDERS_APS_SENT_AD_SERVER_REQUEST = 'BaxterBiddersApsSentAdServerRequest',
  BIDDERS_PREBID_SENT_AD_SERVER_REQUEST = 'BaxterBiddersPrebidSentAdServerRequest',
  BIDDERS_TIMEOUT_SENT_AD_SERVER_REQUEST = 'BaxterBiddersTimeoutSentAdServerRequest',
  GOOGLEIMA_DEPENDENCIES_RESOLVED_WITH_SUCCESS = 'BaxterGoogleImaDependenciesResolvedWithSuccess',
  GOOGLEIMA_DEPENDENCIES_RESOLVED_WITH_ERROR = 'BaxterGoogleImaDependenciesResolvedWithError',
  GOOGLEIMA_AUTOPLAY_WAS_PREVENTED = 'BaxterGoogleImaAutoplayWasPrevented',
  GOOGLEIMA_AUTOPLAY_RESULT_UNDEFINED = 'BaxterGoogleImaAutoplayResultUndefined',
  GOOGLEIMA_AUTOPLAYED = 'BaxterGoogleImaAutoplayed',
  GOOGLEIMA_ADS_ERROR = 'BaxterGoogleImaAdsError',
  GOOGLEIMA_TRY_AUTOPLAY = 'BaxterGoogleImaTryAutoplay',
  GOOGLEIMA_VIDEO_ERROR = 'BaxterGoogleImaVideoError',
  GOOGLEIMA_VIDEO_ERROR_FALLBACK = 'BaxterGoogleImaVideoErrorFallback',
  GOOGLEIMA_VIDEO_ERROR_RESETING_PLAYER = 'BaxterGoogleImaVideoErrorResetingPlayer',
  GOOGLEIMA_ADS_READY = 'BaxterGoogleImaAdsReady',
  GOOGLEIMA_TIMEOUT_FALLBACK = 'BaxterGoogleImaTimeoutFallback',
  GOOGLEIMA_ADS_MANAGER_LOADED = 'BaxterGoogleImaAdsManagerLoaded',
  GOOGLEIMA_DEPENDENCIES_FALLBACK = 'BaxterGoogleImaDependenciesFallback',
  GOOGLEIMA_ADS_ERROR_FALLBACK = 'BaxterGoogleImaAdserrorFallback',
  GOOGLEIMA_SLOT_ALREADY_REMOVED = 'BaxterGoogleImaSlotAlreadyRemovedError',
  STICKY_STICK = 'BaxterStickyStick',
  STICKY_UNSTICK = 'BaxterStickyUnstick',
  STICKY_CLOSE = 'BaxterStickyClose',
  AUTOPLAY_ADD_VISIBILITY_CHANGE_LISTENER = 'BaxterAutoplayAddVisibilityChangeListener',
  AUTOPLAY_VISIBILITY_CHANGED = 'BaxterAutoplayVisibilityChanged',
  ADMANAGER_SLOT_ALREADY_REMOVED = 'BaxterAdManagerSlotAlreadyRemoved',
  ADMANAGER_STATIC_DSA_MODAL = 'BaxterAdManagerStaticDsaModal',
  ADMANAGER_STATIC_SLOT_ALREADY_REMOVED = 'BaxterAdManagerStaticSlotAlreadyRemoved',
  ADSENSE_SLOT_ALREADY_REMOVED = 'BaxterAdSenseSlotAlreadyRemoved',
  ADBLOCK_BTA = 'BaxterAdblockBTA',
  ADBLOCK_ACCEPTABLE = 'BaxterAdblockAcceptableAdsInit',
  LIFECYCLE_ON_SET = 'BaxterLifecycleOnSet',
  LIFECYCLE_ON_PAGE_TRACK_DEPRECATED_USAGE = 'LifecycleOnPageTrackDeprecatedUsage',
}
